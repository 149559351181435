/**
 * These global CSS imports are first as they apply resets that should be injected before any CSS that might be injected by the imported modules below.
 */
import './global.css';

import React, { ReactElement, ReactNode } from 'react';

import Head from 'next/head';
import type { AppProps } from 'next/app';

import { ClerkLoaded, ClerkProvider, useSession } from '@clerk/nextjs';
// import type { ClientResource } from '@clerk/types';
import { AnalyticsProvider } from '@components/analytics/Analytics';

import { NextPage } from 'next';
import { CheckoutProvider } from '@components/checkout/CheckoutProvider';
import { ThemeProvider } from 'next-themes';
import { MotionConfig } from 'framer-motion';
import { Layout } from '@components/layouts/AppLayout/Layout';
import { getDashboardEnvironment } from '@utils/getDashboardEnvironment';
import { Tracker } from '@/app/components/analytics/Tracker';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Listener } from '@/app/components/analytics/Listener';

// We are following the layout pattern from official nextjs docs
// as described here: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  if (typeof window !== 'undefined') {
    window.__SWR_DEVTOOLS_USE__ = false;
  }

  const { isDevelopment } = getDashboardEnvironment();

  const getLayout = Component.getLayout ?? (page => <Layout>{page}</Layout>);

  // const sessionSelector = (client: ClientResource) => {
  //   const params = new URLSearchParams(window.location.search);
  //   const sessionId =
  //     params.get('clerk_session_id') || client.lastActiveSessionId;

  //   const lastActiveSession = client.activeSessions.find(
  //     s => s.id === sessionId,
  //   );

  //   return lastActiveSession || client.activeSessions[0] || null;
  // };

  // we only restrict the redirect origin in development mode
  // in production and staging the default is to allow anywhere same-site
  return (
    <ClerkProvider
      {...(isDevelopment && {
        allowedRedirectOrigins: [
          process.env.NEXT_PUBLIC_DASHBOARD_URL as string,
        ],
      })}
      // afterMultiSessionSingleSignOutUrl='/sign-in/choose'
      // selectInitialSession={sessionSelector}
      experimental={{
        persistClient: true,
      }}
      polling={false}
      appearance={{
        variables: {
          colorPrimary: '#6c47ff',
        },
        layout: {
          shimmer: true,
        },
      }}
      clerkJSVersion='5.27.0-snapshot.v2f940fd'
    >
      <MotionConfig reducedMotion='user'>
        <MultisessionAppSupport>
          <AnalyticsProvider>
            <Analytics />
            <Head>
              <title>Dashboard | Clerk.com</title>
              <meta
                property='og:title'
                content='Dashboard | Clerk.com'
                key='title'
              />
              <meta
                name='viewport'
                content='initial-scale=1.0, width=device-width'
              />
            </Head>
            <ClerkLoaded>
              <CheckoutProvider>
                <ThemeProvider
                  attribute='class'
                  defaultTheme='light'
                  enableSystem={false}
                  disableTransitionOnChange
                >
                  {getLayout(<Component {...pageProps} />)}
                </ThemeProvider>
              </CheckoutProvider>
            </ClerkLoaded>
          </AnalyticsProvider>
        </MultisessionAppSupport>
      </MotionConfig>
    </ClerkProvider>
  );
}

function Analytics() {
  const { page, identify, track } = useAnalytics();

  return (
    <>
      <Tracker page={page} />
      <Listener identify={identify} track={track} />
    </>
  );
}

function MultisessionAppSupport({
  children,
}: React.PropsWithChildren<unknown>) {
  const { session } = useSession();
  return (
    <React.Fragment key={session ? session.id : 'no-users'}>
      {children}
    </React.Fragment>
  );
}

export default App;
