import { Icon, type IconProps as IconName } from '@/app/components/Icon';
import { useControllableState } from '@/app/hooks/useControllableState';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cx } from 'cva';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from './Button';

function easeOut(x: number): number {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

type DialogHeaderProps = {
  title: string | React.ReactNode;
  description?: React.ReactNode;
  hasPadding?: boolean;
};

export function Header({
  title,
  description,
  hasPadding = true,
}: DialogHeaderProps) {
  return (
    <div
      className={cx('relative flex items-start gap-6', {
        'px-5 py-4': hasPadding,
      })}
    >
      <div className='mt-1 flex w-full flex-col gap-1'>
        <RadixDialog.Title className='text-xl font-medium'>
          {title}
        </RadixDialog.Title>
        <RadixDialog.Description className='max-w-[32ch] text-gray-1100'>
          {description}
        </RadixDialog.Description>
      </div>

      <div>
        <RadixDialog.Close asChild>
          <Button icon='x' shape='square' intent='ghost'>
            Close
          </Button>
        </RadixDialog.Close>
      </div>
    </div>
  );
}

type DialogContentProps = {
  children: React.ReactNode;
  transparent?: boolean;
  className?: string;
};

export function Content({
  children,
  transparent,
  className,
}: DialogContentProps) {
  return (
    <div
      className={cx(
        {
          'group/dialog-content dark:border/black/10 overflow-hidden rounded-lg border border-gray-400 bg-white dark:bg-gray-200':
            !transparent,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

export function Section({ children }: { children: React.ReactNode }) {
  return (
    <section data-id='section' className='space-y-5 px-5 pb-6 pt-4'>
      {children}
    </section>
  );
}

export interface NoticeProps {
  className?: string;
  children: React.ReactNode;
  icon?: IconName['name'];
  intent?: 'neutral' | 'warning' | 'danger';
}

export function Notice({
  className,
  children,
  // TODO: This is the wrong icon, we don't have the correct one.
  icon = 'exclamation-circle',
  intent = 'warning',
}: NoticeProps) {
  return (
    <section
      data-id='notice'
      className={cx(
        'block bg-gradient-to-b to-transparent',
        'group-has-[section+section]/dialog-content:border-t',
        {
          'border-gray-300 from-gray-200': intent === 'neutral',
          'border-orange-300 from-orange-200': intent === 'warning',
          'border-red-300 from-red-200': intent === 'danger',
        },
        className,
      )}
    >
      <div
        className={cx(
          'relative flex gap-1.5 px-5 py-3 text-[0.8125rem] font-normal leading-[1.125rem]',
          {
            'text-gray-1100 [&>svg]:text-gray-1000': intent === 'neutral',
            'text-orange-1000 [&>svg]:text-orange-900': intent === 'warning',
            'text-red-1000 [&>svg]:text-red-900': intent === 'danger',
          },
        )}
      >
        <Icon name={icon} size='sm' />
        <div>{children}</div>
      </div>
    </section>
  );
}

type DialogFooterProps = {
  children: React.ReactNode;
};

export function Footer({ children }: DialogFooterProps) {
  return (
    <div className='flex items-center justify-end gap-3 px-5 py-4'>
      {children}
    </div>
  );
}

type DialogCloseProps = {
  children: React.ReactNode;
};

export function Close({ children }: DialogCloseProps) {
  return <RadixDialog.Close asChild>{children}</RadixDialog.Close>;
}

export type DialogProps = {
  trigger?: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (boolean: boolean) => void;
  fullWidth?: boolean;
  transparent?: boolean;
};

export function Root({
  trigger,
  children,
  defaultOpen,
  open: openProp,
  onOpenChange,
  fullWidth,
  transparent,
}: DialogProps) {
  const [open = false, setOpen] = useControllableState({
    defaultProp: defaultOpen,
    onChange: onOpenChange,
    prop: openProp,
  });

  return (
    <RadixDialog.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={setOpen}
    >
      {trigger && <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>}
      <AnimatePresence>
        {open && (
          <RadixDialog.Portal forceMount>
            <RadixDialog.Overlay asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.25 } }}
                transition={{
                  duration: 0.3,
                  ease: easeOut,
                }}
                className='fixed inset-0 flex items-start justify-center overflow-y-auto bg-black/20 py-40 backdrop-blur'
              >
                <RadixDialog.Content asChild>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{
                      opacity: 0,
                      scale: 0.98,
                      transition: { duration: 0.15 },
                    }}
                    transition={{ duration: 0.2, ease: easeOut }}
                    className={cx(
                      !fullWidth && 'w-[94vw] max-w-[440px]',
                      !transparent &&
                        'rounded-xl border border-black/10 bg-gray-300 shadow-lg dark:shadow-[inset_0_0_0_2px_theme(colors.white/0.02),0_1px_1px_-0.5px_theme(colors.black/0.12),0_2px_2px_-1px_theme(colors.black/0.12),0_4px_4px_-2px_theme(colors.black/0.12)]',
                      'focus:outline-none',
                    )}
                  >
                    {children}
                  </motion.div>
                </RadixDialog.Content>
              </motion.div>
            </RadixDialog.Overlay>
          </RadixDialog.Portal>
        )}
      </AnimatePresence>
    </RadixDialog.Root>
  );
}

export const Dialog = {
  Close,
  Content,
  Footer,
  Header,
  Notice,
  Root,
  Section,
};
