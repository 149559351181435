'use client';

import { SignUpModes } from '@/app/api/types/userSettings';
import { URLS_WITH_SIDE_NAV } from '@/app/constants/urls-with-side-nav';
import { getParams, to } from '@/app/utils/url';
import { cx } from 'cva';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo, useRef } from 'react';
import { useMountedState } from 'react-use';

/**
 * Maintenance note:
 * When making a change that leads to an adjustment in the height of
 * `PrimaryNavigation`, please be sure to update `WidthSidebar`'s `top` value
 * for correct sticky positioning
 *
 * Feature flag note:
 * We want to keep header parity between pages and app routers, even for
 * feature/staff mode flagged pages. That means no flags should be optional
 * here, instead fetch and pass them in from both the pages and app router
 * components that uses this.
 */
export function PrimaryNavigation({
  signUpMode,
  isCommerceEnabled,
  isEventLogsEnabled,
}: {
  signUpMode: SignUpModes;
  isCommerceEnabled: boolean;
  isEventLogsEnabled: boolean;
}) {
  const isMounted = useMountedState();

  const leftNavRef = useRef(null);
  const rightNavRef = useRef(null);
  const stickyDetectorRef = useRef(null);

  const isLeftNavInView = useInView(leftNavRef, { amount: 'some' });
  const isRightNavInView = useInView(rightNavRef, { amount: 'some' });
  const isNavVisible = useInView(stickyDetectorRef);

  const isNavStuck = isMounted() && !isNavVisible;
  const isShowingLeftNavFade = isMounted() && !isLeftNavInView;
  const isShowingRightNavFade = isMounted() && !isRightNavInView;

  return (
    <div
      className={cx(
        'sticky top-0 z-10',
        'before:absolute before:inset-x-0 before:bottom-0 before:h-px before:bg-gray-300 before:opacity-0',
        {
          'bg-gray-100 before:opacity-100 dark:border-white/5': isNavStuck,
          'border-transparent': !isNavStuck,
        },
      )}
    >
      <div
        ref={stickyDetectorRef}
        className='absolute inset-x-0 -top-0.5 h-px'
      />

      <div
        className={cx(
          'no-scrollbar relative flex justify-between overflow-x-auto px-4 md:px-5',
          {
            '-top-px': !isNavStuck,
          },
        )}
      >
        <nav className='relative isolate -ml-3.5 flex shrink-0'>
          <div className='absolute inset-y-0 left-0' ref={leftNavRef} />
          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/'>
            Overview
          </PrimaryNavigationItem>

          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/users'>
            Users
          </PrimaryNavigationItem>

          <PrimaryNavigationItem isNavStuck={isNavStuck} href='/organizations'>
            Organizations
          </PrimaryNavigationItem>

          {isCommerceEnabled && (
            <PrimaryNavigationItem isNavStuck={isNavStuck} href='/commerce'>
              Commerce
            </PrimaryNavigationItem>
          )}

          {isEventLogsEnabled && (
            <PrimaryNavigationItem isNavStuck={isNavStuck} href='/eventlogs'>
              Events
            </PrimaryNavigationItem>
          )}

          {signUpMode === 'restricted' && (
            <PrimaryNavigationItem isNavStuck={isNavStuck} href='/invitations'>
              Invitations
            </PrimaryNavigationItem>
          )}

          {signUpMode === 'waitlist' && (
            <PrimaryNavigationItem isNavStuck={isNavStuck} href='/waitlist'>
              Waitlist
            </PrimaryNavigationItem>
          )}

          <PrimaryNavigationItem
            isNavStuck={isNavStuck}
            href='/user-authentication/email-phone-username'
          >
            Configure
          </PrimaryNavigationItem>

          <div className='absolute inset-y-0 right-0' ref={rightNavRef} />
        </nav>
      </div>

      <div
        className={cx(
          'pointer-events-none absolute inset-y-0 left-0 w-16 bg-gradient-to-r from-gray-100 opacity-0 transition-opacity duration-200',
          {
            'opacity-0': !isShowingLeftNavFade,
            'opacity-100': isShowingLeftNavFade,
          },
        )}
      />

      <div
        className={cx(
          'pointer-events-none absolute inset-y-0 right-0 w-16 bg-gradient-to-l from-gray-100 transition-opacity duration-200',
          {
            'opacity-0': !isShowingRightNavFade,
            'opacity-100': isShowingRightNavFade,
          },
        )}
      />
    </div>
  );
}

export function PrimaryNavigationItem(props: {
  children: string;
  href: string;
  isNavStuck: boolean;
  onClick?: () => void;
}) {
  const pathname = usePathname();
  const fullHref = to(pathname!, { page: props.href });

  const { rest } = getParams(pathname ?? '');

  const isActive = useMemo(() => {
    // Check for home route
    if (props.href === '/') {
      return rest === '';
    }

    // The Configure tab is active if the current route matches any of the
    // paths defined in URLS_WITH_SIDE_NAV
    if (props.children === 'Configure') {
      return URLS_WITH_SIDE_NAV.some(url => pathname?.includes(url));
    }

    // This prevents conflicts between main nav items and Configure sub-items
    return (
      pathname?.includes(props.href) &&
      !URLS_WITH_SIDE_NAV.some(url => pathname?.includes(url))
    );
  }, [props.href, props.children, pathname, rest]);

  return (
    <Link
      href={fullHref}
      className={cx(
        // See above "maintenance note"
        'relative shrink-0 p-3.5 font-book text-gray-1100 transition',
        'hover:text-gray-1200',
        'after:absolute after:-inset-x-0.5 after:inset-y-2 after:rounded-lg after:border-2 after:border-blue after:opacity-0',
        'focus-visible:after:opacity-100',
        {
          'text-gray-1200': isActive,
        },
      )}
    >
      {props.children}
      <AnimatePresence>
        {isActive && (
          <motion.div
            layoutId='active-nav-item'
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
            className={cx('absolute inset-x-3.5 h-px bg-gray-1200', {
              'bottom-0': props.isNavStuck,
              'top-0': !props.isNavStuck,
            })}
          />
        )}
      </AnimatePresence>
    </Link>
  );
}
