import { ExternalToast, toast } from '@/app/components/ui/Toast';
import { HTTPError, getToastErrorMessage } from '@/app/utils/errors';
import * as Sentry from '@sentry/nextjs';
import {
  DeepKeys,
  FieldMeta,
  FormApi,
  FormOptions,
  ReactFormApi,
  useForm,
} from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { isEqual } from 'lodash';
import {
  RefObject,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { ZodIssue } from 'zod';

// We choose this delimiter because:
//   * We can still use "word, word" in errors
//   * If we forget to split the individual errors out (normally by using
//     <FieldErrors>), it still looks okay-ish compared to alternatives
export const VALIDATION_ERROR_DELIMITER = ' , ';

const VALIDATOR = zodValidator({
  // This is the default one, copied from src. Putting it here for visibility
  // since we will probably want to tweak this later.
  transformErrors: (issues: ZodIssue[]) =>
    issues.map(issue => issue.message).join(VALIDATION_ERROR_DELIMITER),
});
export type ZodValidatorType = typeof VALIDATOR;

export type FormType<TFormData> = { formId: string } & FormApi<
  TFormData,
  ZodValidatorType
> &
  ReactFormApi<TFormData, ZodValidatorType>;

function getAllValidationErrors<TFormData>(
  formState: FormType<TFormData>['state'],
) {
  const fieldErrors = Object.values(formState.fieldMeta).flatMap(
    (fieldMeta: FieldMeta) => fieldMeta.errors,
  );
  return [...formState.errors, ...fieldErrors];
}

export const useControlledForm = <TFormData>(
  formRef: RefObject<HTMLFormElement>,
  opts?: FormOptions<TFormData, typeof VALIDATOR>,
  showToast = true,
): FormType<TFormData> => {
  const formId = useId();
  const toastId = useRef<string>();

  const form = useForm({ validatorAdapter: VALIDATOR, ...opts });

  /**
   * We use a custom `isDirty` check because the default one is... "singular".
   * - Our implementation allows the state to go back to false when the
   *   form state is the same as the default values.
   * - The default implementation will return true when/if any of the values
   *   have ever changed, basically a `isTouched` check, not a `isDirty` per se.
   * @see https://arc.net/l/quote/ckjcjyfo
   */
  const isDirty = form.useStore(
    ({ values }) => !isEqual(form.options.defaultValues, values),
  );

  const dismissToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = undefined;
    }
  };

  useEffect(() => {
    if (!showToast) {
      return; // Ignore the toast logic if we opt out
    }
    if (!isDirty) {
      return dismissToast(); // Dismiss any existing toast
    }

    // We store the toastId in a ref so we can dismiss it later
    toastId.current = toast('Unsaved changes', {
      confirm: {
        label: 'Save',
        form: formId,
        onClick: () => {
          if (!form.store.state.canSubmit) {
            return toast.warning('Please check the form for errors');
          }

          // form.handleSubmit();
          formRef.current?.requestSubmit();
        },
      },
      deny: {
        label: 'Reset',
        onClick: () => {
          form.reset();
        },
      },
      // We want the toast to stay until the user interacts with it
      duration: Infinity,
    });

    return dismissToast; // Dismiss the toast when the component unmounts
  }, [isDirty, formRef, form, showToast, formId]);

  return useMemo(
    () => ({
      formId,
      ...form,
      runValidator: form.runValidator,
      setErrorMap: form.setErrorMap,
    }),
    [formId, form],
  );
};

type InternalFormState = { effect?: () => void } & (
  | { stage: 'CLEAN' }
  | { stage: 'DIRTY' }
  | { stage: 'PENDING' }
  | { stage: 'ERROR'; error?: unknown }
  | { stage: 'SUCCESS' }
);
type InternalFormEvent =
  | { name: 'TOUCH' }
  | { name: 'UNTOUCH' }
  | { name: 'SUBMIT' }
  | { name: 'FAILURE'; error?: unknown }
  | { name: 'SUBMIT_SUCCESS' }
  | { name: 'COMPLETE' };
type ReducerType = (
  state: InternalFormState,
  event: InternalFormEvent,
) => InternalFormState;
const reducerLogger =
  (enabled: boolean, reducer: ReducerType) =>
  (state: InternalFormState, event: InternalFormEvent) => {
    const result = reducer(state, event);
    if (enabled) {
      // eslint-disable-next-line no-console
      console.log(
        `Stage: ${state.stage} -> Event: ${event.name} -> Stage: ${result.stage}`,
      );
    }
    return result;
  };

type OnFormSubmitType<TFormData> = Required<
  FormOptions<TFormData, typeof VALIDATOR>
>['onSubmit'];
type OnFormSubmitInvalidType<TFormData> = Required<
  FormOptions<TFormData, typeof VALIDATOR>
>['onSubmitInvalid'];
type ExtraOptions = {
  debug?: boolean;
  toastMessages?: Partial<typeof DEFAULT_TOAST_MESSAGES>;
};
export const useToastForm = <TFormData>(
  formOpts: Omit<FormOptions<TFormData, typeof VALIDATOR>, 'onSubmit'> & {
    /**
     * For the form to work correctly, you must pass in onSubmit and it must
     * return a promise that only resolves when the submit is complete.
     */
    onSubmit: (
      ...args: Parameters<OnFormSubmitType<TFormData>>
      // We are overwriting this to only accept functions that returns promises,
      // which this hook relies on for managing the toast
    ) => Promise<unknown>;
  },
  { debug = false, toastMessages: toastMessagesArg = {} }: ExtraOptions = {},
): FormType<TFormData> => {
  const toastMessages = { ...DEFAULT_TOAST_MESSAGES, ...toastMessagesArg };

  const formId = useId();
  const toastId = useRef<string>();

  const onSubmit = formOpts.onSubmit;
  const onSubmitWrapped: OnFormSubmitType<TFormData> = useCallback(
    async args => {
      const { formApi } = args;
      dispatch({ name: 'SUBMIT' });

      let result;
      try {
        result = await onSubmit(args);
        dispatch({ name: 'SUBMIT_SUCCESS' });
      } catch (error) {
        // Note: Some global errors could also be form level validation errors
        //       and could theoretically be synced to the form level, but we
        //       currently don't know which ones are validation and which ones
        //       are other errors, so we only sync field level errors here.
        if (error instanceof HTTPError) {
          syncFieldErrorsToForm(error, formApi);

          // This might capture errors that are expected too, so might need
          // to narrow it, but this should also help us find global errors
          // that should actually be fields errors
          if (error.globalErrors.length > 0) {
            Sentry.captureException(error.globalErrors);
          }
        } else {
          // This will also capture things like network errors, which are
          // expected so we might or might not want to narrow this down later
          Sentry.captureException(error);
        }

        dispatch({ name: 'FAILURE', error });
        return;
      }

      return result;
    },
    [onSubmit],
  );

  const onSubmitInvalid = formOpts.onSubmitInvalid;
  const onSubmitInvalidWrapped: OnFormSubmitInvalidType<TFormData> =
    useCallback(
      args => {
        // "Normal" FE-validation errors that happen eg. onChange wont trigger
        // the toast to go into an error mode, that happens here when the
        // user actively tries to submit with active validation-errors.
        dispatch({ name: 'FAILURE' });

        if (onSubmitInvalid) {
          return onSubmitInvalid(args);
        }
      },
      [onSubmitInvalid],
    );

  const form = useForm({
    validatorAdapter: VALIDATOR,
    ...formOpts,
    onSubmit: onSubmitWrapped,
    onSubmitInvalid: onSubmitInvalidWrapped,
  });

  const dismissToast = useCallback(() => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = undefined;
    }
  }, []);

  /**
   * This reducer might seem a bit unorthodox at first glance, the pattern is
   * borrowed Dan Abramovs implementation of hover cards at Bluesky:
   *   https://github.com/bluesky-social/social-app/pull/3547
   *
   * - Sections starts with a function describing a transition into this stage.
   * - After that are all valid transitions out from the stage.
   * - Some stages saves effect-functions to state. These are not executed in
   *   the reducer as this would break React rules, instead they are executed in
   *   an effect. This let's us co-locate state descriptions and side effects.
   * - A separate useEffect syncs this state to the form-toast
   */
  const [formState, dispatch] = useReducer<ReducerType>(
    reducerLogger(debug, (state, event) => {
      // ----- CLEAN -----
      function toClean(): InternalFormState {
        // We don't want to dismiss the toast in an effect cleanup on each
        // stage transition as that would break animations, so instead we
        // do it explicitly when moving _into_ the CLEAN stage.
        return { stage: 'CLEAN', effect: dismissToast };
      }
      if (state.stage === 'CLEAN') {
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      // ----- DIRTY -----
      function toDirty(): InternalFormState {
        return { stage: 'DIRTY' };
      }
      if (state.stage === 'DIRTY') {
        if (event.name === 'UNTOUCH') {
          return toClean();
        }
        if (event.name === 'SUBMIT') {
          return toPending();
        }
        if (event.name === 'FAILURE') {
          return toError(event);
        }
      }

      // -- PENDING --
      function toPending(): InternalFormState {
        return { stage: 'PENDING' };
      }
      if (state.stage === 'PENDING') {
        if (event.name === 'SUBMIT_SUCCESS') {
          return toSuccess();
        }
        if (event.name === 'FAILURE') {
          return toError(event);
        }
      }

      // ----- ERROR -----
      function toError(
        event: Extract<InternalFormEvent, { name: 'FAILURE' }>,
      ): InternalFormState {
        // TODO: Until we feel sure we expose all form errors to the user,
        //       we log all errors to the console so we have some means of
        //       debugging. When we do feel sure, we can uncomment the if.
        // if (debug) {
        // eslint-disable-next-line no-console
        console.log('Form error', {
          error: event.error,
          validationErrors: getAllValidationErrors(form.state),
        });
        // }

        return {
          stage: 'ERROR',
          // Only some errors are saved in state, we read validation errors
          // directly from the form
          error: event.error,
          effect: () => {
            if (state.stage !== 'ERROR' && toastId.current) {
              // Trigger the jiggle manually when moving into the error stage,
              // we don't want this to happen continually in an effect
              toast.update(toastId.current, { jiggle: true });
            }
          },
        };
      }
      if (state.stage === 'ERROR') {
        if (event.name === 'FAILURE') {
          // Even if we are already in error, this might be _another_ error,
          // this also makes the toast jiggle again
          return toError({
            name: event.name,
            // Keep the old error if the event does not have a new one
            error: event.error || state.error || undefined,
          });
        }
        if (event.name === 'SUBMIT') {
          return toPending();
        }
        if (event.name === 'UNTOUCH') {
          return toClean();
        }
        // The only time TOUCH happens when we are in an error state is
        // when we've had a valiation error disappear
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      // ----- SUCCESS -----
      function toSuccess(): InternalFormState {
        return {
          stage: 'SUCCESS',
          effect: () => {
            form.reset();

            // Keep the toast open for a bit..
            const timeoutId = setTimeout(() => {
              dispatch({ name: 'COMPLETE' });
            }, 1500);

            // ..but cancel if user transitions out of this state early
            return () => {
              clearTimeout(timeoutId);
            };
          },
        };
      }
      if (state.stage === 'SUCCESS') {
        if (event.name === 'COMPLETE') {
          return toClean();
        }
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      return state;
    }),
    { stage: 'CLEAN' },
  );

  /**
   * We use a custom `isDirty` check because the default one is... "singular".
   * - Our implementation allows the state to go back to false when the
   *   form state is the same as the default values.
   * - The default implementation will return true when/if any of the values
   *   have ever changed, basically a `isTouched` check, not a `isDirty` per se.
   * @see https://arc.net/l/quote/ckjcjyfo
   */
  const { isDirty, canSubmit, isValid } = form.useStore(
    ({ values, canSubmit, isValid }) => ({
      isDirty: !isEqual(form.options.defaultValues, values),
      canSubmit,
      isValid,
    }),
  );
  const handleSubmit = form.handleSubmit;
  const formReset = form.reset;

  const save = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (!canSubmit) {
        if (toastId.current) {
          toast.update(toastId.current, { jiggle: true });
        }
        dispatch({ name: 'FAILURE' });
        return;
      }
      void handleSubmit();
    },
    [canSubmit, handleSubmit],
  );

  const reset = useCallback(() => {
    formReset();
  }, [formReset]);

  useEffect(
    function syncDirtyToFormState() {
      if (isDirty) {
        dispatch({ name: 'TOUCH' });
      } else {
        dispatch({ name: 'UNTOUCH' });
      }
    },
    [isDirty],
  );

  const formStateError =
    formState.stage === 'ERROR' ? formState.error : undefined;
  useEffect(() => {
    if (formState.stage === 'ERROR' && !formStateError && isValid) {
      // This handles the special case where we've gone from having a
      // validation error to not having it anymore
      dispatch({ name: 'TOUCH' });
    }
  }, [formState.stage, formStateError, isValid]);

  const formToasts = useFormToasts(formId, save, reset, toastMessages);

  useEffect(
    function syncStateToToast() {
      function showOrUpdateToast(
        newToast: ExternalToast & { message: string },
      ) {
        if (toastId.current) {
          toast.update(toastId.current, newToast);
        } else {
          const { message, ...options } = newToast;
          toastId.current = toast(message, options);
        }
      }

      // We don't have one for CLEAN, instead we close the toast imperatively
      // in an effect when moving into the CLEAN stage, see reducer
      if (formState.stage === 'DIRTY') {
        showOrUpdateToast(formToasts.unsavedToast());
      } else if (formState.stage === 'PENDING') {
        showOrUpdateToast(formToasts.pendingToast());
      } else if (formState.stage === 'ERROR') {
        // Prio:
        //   - Unknown errors & Global DAPI errors
        //   - If form invalid - 'Please check ...'
        //   - If form valid - 'Could not save ...'
        const errorMessage = getToastErrorMessage(
          formStateError,
          isValid ? toastMessages.unknownError : toastMessages.validationError,
        );
        showOrUpdateToast(formToasts.errorToast(errorMessage));
      } else if (formState.stage === 'SUCCESS') {
        showOrUpdateToast(formToasts.successToast());
      }
    },
    [
      formState.stage,
      formStateError,
      formToasts,
      isValid,
      toastMessages.unknownError,
      toastMessages.validationError,
    ],
  );

  // Execute effects saved in formState
  const effect = formState?.effect;
  useEffect(() => {
    if (effect) {
      return effect();
    }
  }, [effect]);

  useEffect(() => {
    return () => {
      dismissToast?.();
    };
  }, [dismissToast]);

  return useMemo(
    () => ({
      formId,
      ...form,
      // Makes TS stop complaining
      runValidator: form.runValidator,
      setErrorMap: form.setErrorMap,
    }),
    [formId, form],
  );
};

const DEFAULT_TOAST_MESSAGES = {
  unsaved: 'Unsaved changes',
  save: 'Save',
  reset: 'Reset',
  tryAgain: 'Try again',
  pending: 'Saving changes...',
  success: 'Saved!',
  unknownError: 'Could not save settings',
  validationError: 'Please check the form for errors',
};
function useFormToasts(
  formId: string,
  save: (event: { preventDefault: () => void }) => void,
  reset: () => void,
  toastMessages: typeof DEFAULT_TOAST_MESSAGES,
) {
  return useMemo(() => {
    return {
      unsavedToast: () =>
        ({
          message: toastMessages.unsaved,
          intent: 'info',
          confirm: {
            label: toastMessages.save,
            onClick: save,
            form: formId,
          },
          deny: {
            label: toastMessages.reset,
            onClick: reset,
          },
          disableCloseAction: true,
          duration: Infinity,
        }) as const,
      // TODO: We might want to make this a bit more advanced, for example:
      //       - Make it expandable so users always has access to all errors
      //       - Click/touch focuses the first field with errors
      //       - Improve a11y - Make keyboard accessible
      errorToast: (message: string) =>
        ({
          message,
          intent: 'error',
          confirm: {
            label: toastMessages.tryAgain,
            onClick: save,
            form: formId,
          },
          deny: {
            label: toastMessages.reset,
            onClick: reset,
          },
          disableCloseAction: true,
          duration: Infinity,
          jiggle: false,
        }) as const,
      pendingToast: () =>
        ({
          intent: 'info',
          message: toastMessages.pending,
          confirm: undefined,
          deny: undefined,
          duration: Infinity,
          jiggle: false,
        }) as const,
      successToast: () =>
        ({
          message: toastMessages.success,
          intent: 'success',
          confirm: undefined,
          deny: undefined,
          duration: Infinity,
          jiggle: false,
        }) as const,
    };
  }, [
    toastMessages.unsaved,
    toastMessages.save,
    toastMessages.reset,
    toastMessages.tryAgain,
    toastMessages.pending,
    toastMessages.success,
    save,
    formId,
    reset,
  ]);
}

export function syncFieldErrorsToForm<TFormData>(
  error: HTTPError,
  formApi: FormApi<TFormData, ZodValidatorType>,
) {
  for (const fieldError of error.fieldErrors) {
    if (Object.keys(formApi.fieldInfo).includes(fieldError.meta.param_name)) {
      formApi.setFieldMeta(
        fieldError.meta.param_name as DeepKeys<TFormData>,
        oldMeta => {
          const oldMessage = oldMeta.errorMap.onSubmit
            ? oldMeta.errorMap.onSubmit + ', '
            : '';
          const newMessage = fieldError.long_message || fieldError.message;
          return {
            ...oldMeta,
            errorMap: {
              onSubmit: oldMessage + newMessage,
            },
          };
        },
      );
    } else {
      Sentry.captureException(
        new Error(
          `Form: DAPI returned error with param_name: "${fieldError.meta.param_name}" which has no field on form - This error will not be exposed to the user`,
        ),
      );
    }
  }
}
