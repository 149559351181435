import { Icon } from '@/app/components/Icon';
import { VALIDATION_ERROR_DELIMITER } from '@/app/hooks/useControlledForm';
import { ValidationError } from '@tanstack/react-form';
import { Truthy } from 'lodash';

export function FieldErrors({
  errors,
  id,
  className,
}: {
  errors: ValidationError | ValidationError[];
  id?: string;
  className?: string;
}) {
  const arrayErrors = Array.isArray(errors) ? errors : [errors];
  const filteredErrors = arrayErrors.filter(
    (error): error is Truthy<ValidationError> => Boolean(error),
  );
  const flatArray = filteredErrors.flatMap(error =>
    error.split(VALIDATION_ERROR_DELIMITER),
  );

  if (flatArray.length === 0) {
    return null;
  }

  return (
    <div id={id} className={className}>
      {flatArray.map((error, index) => {
        return (
          <div key={index} className='flex items-center gap-1.5 text-red-1000'>
            <Icon
              name='exclamation-octagon'
              size='sm'
              className='text-red-900'
            />
            {error}
          </div>
        );
      })}
    </div>
  );
}
