'use client';

import { Application } from '@/app/api/types/applications';
import { BaseInstance, Instance } from '@/app/api/types/instances';
import { Icon } from '@/app/components/Icon';
import { NavigationBreadcrumbButton } from '@/app/components/NavigationBreadcrumbButton';
import { to } from '@/app/utils/url';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cx } from 'cva';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { CreateProductionInstanceDialog } from './CreateProductionInstanceDialog';
// TODO: Remove these when not needed
import { Application as PagesApplication } from '@/dapi/applications/types';
import { Instance as PagesInstance } from '@/dapi/instances/types';

type Props = {
  track: (message: string, properties?: any) => void;
  invalidateApplication: () => Promise<unknown>;
  showPaymentModal: ({ features, exceptionForUpgradeModal }) => void;
  application: Application | PagesApplication;
  currentInstance: BaseInstance | PagesInstance;
};
export function EnvironmentSwitcher({
  track,
  invalidateApplication,
  showPaymentModal,
  application,
  currentInstance,
}: Props) {
  const pathname = usePathname()!;

  const activeInstance = application?.instances.find(
    ({ id }) => id === currentInstance?.id,
  );

  const onSelectInstance = (clickedInstance: Instance) => {
    track('Dashboard_Top Nav_Instance changed', {
      surface: 'Dashboard',
      location: 'Top Nav',
      currentInstanceType: activeInstance
        ? activeInstance.environment_type
        : '',
      selectedInstanceType: clickedInstance.environment_type,
    });
  };

  const canCreateProductionInstance = () => {
    return !application?.instances?.some(
      instance => instance.environment_type === 'production',
    );
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <NavigationBreadcrumbButton>
          <span className='flex items-center gap-2.5'>
            <span
              className={cx(
                'block size-1.5 shrink-0 rounded-full shadow-[0_0_0_3px]',
                {
                  'bg-orange shadow-orange/25':
                    currentInstance.environment_type === 'development',
                  'bg-sky-500 shadow-sky-500/25':
                    currentInstance.environment_type === 'staging',
                  'bg-purple shadow-purple/25':
                    currentInstance.environment_type === 'production',
                },
              )}
            />
            <span className='truncate capitalize'>
              {currentInstance.environment_type}
            </span>
          </span>
        </NavigationBreadcrumbButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={e => e.preventDefault()}
          align='start'
          sideOffset={4}
          className={cx(
            'w-60 rounded-lg border border-black/5 bg-white shadow-lg dark:border-black/5 dark:bg-gray-300 dark:shadow-black/20',
            'before:absolute before:inset-0 before:rounded-inherit before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition',
          )}
        >
          <DropdownMenu.Group className='p-px'>
            {application.instances.map(instance => {
              const isSelected =
                instance.environment_type === currentInstance.environment_type;

              return (
                <Link
                  key={instance.id}
                  href={to(pathname, { instanceId: instance.id })}
                  onClick={() => onSelectInstance(instance)}
                >
                  <DropdownMenu.Item
                    className={cx(
                      'group relative isolate flex cursor-pointer items-center gap-2 px-2 py-1.5 font-book capitalize outline-none transition focus-visible:text-gray-1200',
                      'before:absolute before:inset-px before:-z-10 before:rounded before:border before:border-black/1 before:bg-gray-200 before:opacity-0 data-[highlighted]:before:opacity-100 dark:before:bg-gray-400/60',
                    )}
                  >
                    <span className='flex items-center gap-2.5'>
                      <span className='size-4 flex-none opacity-60'>
                        {isSelected && <Icon name='checkmark' size='sm' />}
                      </span>
                      <span
                        className={cx('truncate capitalize', {
                          'text-gray-1100 group-hover:text-gray-1200':
                            !isSelected,
                          'text-gray-1200 shadow-[0_0_0_1px_theme(colors.white/0.02)_inset,0_1px_0_0_theme(colors.white/0.04)_inset]':
                            isSelected,
                        })}
                      >
                        {instance.environment_type}
                      </span>
                    </span>
                  </DropdownMenu.Item>
                </Link>
              );
            })}

            {canCreateProductionInstance() && (
              <CreateProductionInstanceDialog
                track={track}
                instances={application.instances}
                invalidateApplication={invalidateApplication}
                showPaymentModal={showPaymentModal}
                trigger={
                  <DropdownMenu.Item
                    data-analytics='create-production-instance-modal-opened'
                    onSelect={event => {
                      event.preventDefault();
                    }}
                    className={cx(
                      'relative isolate flex cursor-pointer items-center gap-2 px-2 py-1.5 font-book text-purple outline-none transition focus-visible:text-purple',
                      'before:absolute before:inset-px before:-z-10 before:rounded before:border before:border-purple/1 before:bg-purple-200 before:opacity-0 data-[highlighted]:before:opacity-100 dark:before:bg-purple-400/60',
                    )}
                  >
                    <Icon
                      name='plus'
                      size='sm'
                      className='size-4 flex-none font-book text-purple'
                    />
                    Create production instance
                  </DropdownMenu.Item>
                }
              />
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
