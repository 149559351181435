'use client';

import { Icon } from '@/app/components/Icon';
import { Label } from '@/app/components/ui/Label';
import { ValidationError } from '@tanstack/react-form';
import { cva, cx, VariantProps } from 'cva';
import React, { forwardRef, useId, useMemo } from 'react';
import { mergeProps, useFocusRing } from 'react-aria';
import { FieldErrors } from './FieldErrors';

interface InputProps
  extends Pick<
    React.ComponentProps<'input'>,
    | 'value'
    | 'defaultValue'
    | 'onChange'
    | 'onFocus'
    | 'onBlur'
    | 'aria-label'
    | 'name'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'autoFocus'
    | 'autoComplete'
    | 'autoCorrect'
    | 'spellCheck'
    | 'min'
    | 'max'
    | 'id'
    | 'readOnly'
    | 'maxLength'
  > {
  label?: string;
  description?: string;
  helpText?: string;
  className?: string;
  height?: VariantProps<typeof root>['height'];
  width?: string | number;
  prefix?: React.ReactNode;
  prefixClassName?: string;
  optional?: boolean;
  type?:
    | 'text'
    | 'search'
    | 'email'
    | 'number'
    | 'url'
    | 'password'
    | 'datetime-local';
  /**
   * Append an element after the input within the root container
   * @example: <Input append={<button>...</button} />
   */
  append?: React.ReactNode;
  /**
   * Use this to indicate Input should be in an error state in cases where you
   * don't want to pass in and show the errors themselves.
   */
  hasError?: boolean;
  /**
   * Use this together with hasError to link the Input to an error displayed
   * elsewhere for assistive technology.
   */
  'aria-errormessage'?: string;
  errors?: ValidationError | ValidationError[];
  ref?: React.ForwardedRef<InputRef>;
}

const root = cva({
  base: [
    'relative flex transition',
    'rounded shadow-[0px_2px_2px_-1px_rgba(0,0,0,0.06),_0px_0px_0px_1px_rgba(25,28,33,0.12),_0px_4px_4px_-2px_rgba(0,0,0,0.04)]',
  ],
  variants: {
    height: {
      auto: null,
      full: 'h-full',
    },
    disabled: {
      true: 'opacity-50',
    },
    focusState: {
      '': null,
      focusVisible:
        'ring-[0.1875rem] ring-blue/40 ring-offset-1 ring-offset-blue/50',
      focused:
        'ring-[0.1875rem] ring-black/8 ring-offset-1 ring-offset-black/2',
    },
    type: {
      email: null,
      number: null,
      password: null,
      search: 'pl-5',
      text: null,
      url: null,
      'datetime-local': null,
    },
    error: {
      true: '!shadow-[0px_2px_2px_-1px_rgba(0,0,0,0.06),_0px_0px_0px_1px_rgb(248,113,113),_0px_4px_4px_-2px_rgba(0,0,0,0.04)]',
    },
  },
  compoundVariants: [
    {
      focusState: ['focusVisible', 'focused'],
      error: true,
      class: 'ring-[rgb(248,113,113)]/[0.24] !ring-offset-[rgb(248,113,113)]',
    },
  ],
});

type InputRef = HTMLInputElement;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  props: InputProps,
  ref,
) {
  const internalID = React.useId();
  const {
    label,
    description,
    helpText,
    optional,
    disabled,
    type,
    id: externalId,
    width,
    height,
    prefix,
    className,
    prefixClassName,
    ...rest
  } = props;

  const id = externalId ? externalId : internalID;
  const { isFocusVisible, focusProps, isFocused } = useFocusRing();
  const helpTextId = useId();
  const internalErrorId = useId();
  const hasError = (props.errors && props.errors.length > 0) || props.hasError;
  let errorMessageId;
  if (hasError) {
    if (props.errors) {
      errorMessageId = internalErrorId;
    } else {
      errorMessageId = props['aria-errormessage'];
    }
  }

  const focusState = useMemo(() => {
    if (isFocusVisible) {
      return 'focusVisible';
    }
    if (isFocused) {
      return 'focused';
    }
    return '';
  }, [isFocusVisible, isFocused]);

  return (
    <div
      className={cx(
        'flex flex-col gap-y-2',
        { auto: null, full: 'h-full' }[height || 'auto'],
      )}
      style={width ? { width: width } : {}}
    >
      {label ? (
        <Label
          label={label}
          htmlFor={id}
          labelDetail={description}
          optional={optional}
          disabled={disabled}
        />
      ) : null}

      <div
        className={root({
          disabled,
          focusState,
          type,
          error: hasError,
        })}
      >
        {prefix ? (
          <div
            className={cx(prefixClassName, {
              'flex flex-shrink-0 items-center rounded-l-inherit border-r border-gray-400 bg-gray-200 px-3 text-gray-1100':
                !prefixClassName,
            })}
          >
            {prefix}
          </div>
        ) : null}
        {type === 'search' && (
          <div className='absolute left-2.5 top-1/2 -translate-y-1/2 text-gray-900'>
            <Icon name='magnifying-glass' size='sm' />
          </div>
        )}
        <input
          ref={ref}
          {...mergeProps(focusProps, rest)}
          id={id}
          autoComplete='off'
          className={cx(
            className,
            'block w-full rounded px-3 py-1.5 placeholder:text-gray-900 dark:bg-gray-300',
            {
              'cursor-not-allowed': disabled,
              'rounded-l-none': prefix,
            },
          )}
          type={type}
          aria-describedby={
            props.errors && props.errors.length > 0
              ? errorMessageId
              : helpTextId
          }
          disabled={disabled}
          aria-invalid={hasError}
          aria-errormessage={errorMessageId}
        />
        {props.append ? props.append : null}
      </div>

      {props.errors && props.errors.length > 0 ? (
        <FieldErrors id={internalErrorId} errors={props.errors} />
      ) : props.helpText ? (
        <div
          className='flex items-center gap-1 text-sm text-gray-1100'
          id={helpTextId}
        >
          <Icon name='information-square' size='sm' />
          {helpText}
        </div>
      ) : null}
    </div>
  );
});
