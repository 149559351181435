export type Framework = {
  name: string;
  slug: string;
  publishable_key: string;
  secret_key?: string;
  envFile: string;
  url?: string;
  codeSnippet?: boolean;
  logo: string;
  guide_url?: string;
  video_url?: string;
};

const nextJs = {
  name: 'Next.js',
  slug: 'nextjs',
  publishable_key: 'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env.local',
  logo: '/assets/logos/nextjs.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-nextjs',
};

const react = {
  name: 'React',
  slug: 'react',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  envFile: '.env',
  logo: '/assets/logos/react.svg',
  guide_url:
    'https://clerk.com/docs/quickstarts/get-started-with-create-react-app',
};

const remix = {
  name: 'Remix',
  slug: 'remix',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/remix.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-remix',
};

const redwoodjs = {
  name: 'RedwoodJS',
  slug: 'redwoodjs',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/redwoodjs.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-redwoodjs',
};

const gatsby = {
  name: 'Gatsby',
  slug: 'gatsby',
  publishable_key: 'GATSBY_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/gatsby.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/get-started-with-gatsby',
};

const expo = {
  name: 'Expo',
  slug: 'expo',
  publishable_key: 'EXPO_PUBLIC_CLERK_PUBLISHABLE_KEY',
  envFile: '.env',
  logo: '/assets/logos/expo.svg',
  guide_url: 'https://clerk.com/docs/reference/clerk-expo',
};

const chrome = {
  name: 'Chrome Extension',
  slug: 'chrome',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  envFile: '.env',
  logo: '/assets/logos/chrome.svg',
  url: 'CLERK_FRONTEND_API',
};

const astro = {
  name: 'Astro',
  slug: 'astro',
  publishable_key: 'PUBLIC_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/astro.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/astro',
};

const javascript = {
  name: 'JavaScript',
  slug: 'javascript',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  envFile: 'JavaScript',
  codeSnippet: true,
  logo: '/assets/logos/javascript.svg',
  guide_url: 'https://clerk.com/docs/reference/clerkjs/installation',
};

const tanstack = {
  name: 'TanStack Start',
  slug: 'tanstack',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/tanstack.svg',
  guide_url: 'https://clerk.com/docs/reference/tanstack-start/installation',
};

export const JS_FRAMEWORKS: Framework[] = [
  nextJs,
  react,
  remix,
  redwoodjs,
  gatsby,
  expo,
  chrome,
  astro,
  javascript,
  tanstack,
];

export const START_BUILDING_FRAMEWORKS: Framework[] = [
  nextJs,
  react,
  remix,
  astro,
  expo,
  javascript,
  tanstack,
];
