import {
  ApplicationHeader,
  PathDivider,
} from '@/app/components/ApplicationHeader';
import { ApplicationSwitcher } from '@/app/components/ApplicationSwitcher';
import { EnvironmentSwitcher } from '@/app/components/EnvironmentSwitcher';
import { OrganizationSwitcher } from '@/app/components/OrganizationSwitcher';
import { PrimaryNavigation } from '@/app/components/ui/PrimaryNavigation';
import { usePaymentRequired } from '@/context/PaymentRequired/PaymentRequiredContext';
import { useFetchApplications } from '@/dapi/applications/queries';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useApplication } from '@/hooks/useApplication';
import { useInstance } from '@/hooks/useInstance';
import { useUserSettings } from '@/hooks/useUserSettings';
import { Toaster } from '@clerk/ceramic/components/Toast';
import { Icon } from '@clerk/ceramic/experimental/components/Icon';
import { OrganizationSwitcher as ClerkOrganizationSwitcher } from '@clerk/nextjs';
import { useCheckout } from '@components/checkout/checkoutContext';
import { useLocation } from '@hooks/useLocation';
import { useMembership } from '@hooks/useMembership';
import { cx } from 'cva';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';
import { Billing } from '../planAndBilling/organization/Billing';
import { BillingProvider } from '../planAndBilling/organization/BillingProvider';
import { BannerManager } from '@/app/components/banners/banner-manager';
import { useCurrentSubscription } from '../planAndBilling/currentSubscription/context/CurrentSubscriptionProvider';
import { useStaffMode } from '../sidebar/StaffMode';

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const isAppsNew = router.pathname === '/apps/new';
  const { state } = useCheckout();
  const { isHome } = useLocation();

  return (
    <div className='isolate flex flex-shrink-0 flex-col'>
      {!isHome && <BannerManagerWrapper />}

      {!isAppsNew ? (
        <ApplicationHeader
          switchers={<Switchers />}
          primaryNavigation={
            (!isHome && !state.isCheckoutVisible && (
              <PrimaryNavigationPagesWrapper />
            )) ??
            null
          }
        />
      ) : null}

      <div className='flex flex-1'>
        <main
          className={cx(
            isAppsNew || state.isCheckoutVisible
              ? 'flex h-screen w-full flex-col'
              : 'relative isolate w-full flex-1',
          )}
        >
          <div
            className={cx(
              isAppsNew || state.isCheckoutVisible
                ? 'h-full w-full overflow-hidden'
                : 'mt-4 max-w-6xl gap-12 pb-20 max-sm:w-full max-sm:overflow-x-auto max-sm:px-3 sm:mx-auto sm:w-[calc(100%-theme(spacing.10))] lg:mt-10 lg:pb-10',
            )}
          >
            {children}
          </div>
        </main>
      </div>

      <Toaster />
    </div>
  );
};

function Switchers() {
  const { isHome } = useLocation();
  const { isLoaded, isAdmin } = useMembership();

  return (
    <motion.div
      className='flex items-center'
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoaded ? 1 : 0 }}
      transition={{ duration: 0.15, delay: 0.1 }}
    >
      <BillingProvider>
        <OrganizationSwitcher>
          {isAdmin && (
            <ClerkOrganizationSwitcher.OrganizationProfilePage
              label='Billing'
              url='billing'
              labelIcon={<Icon name='credit-card' size='sm' />}
            >
              <Billing />
            </ClerkOrganizationSwitcher.OrganizationProfilePage>
          )}
        </OrganizationSwitcher>
      </BillingProvider>

      {!isHome && (
        <>
          <PathDivider />
          <ApplicationSwitcherPagesWrapper />
          <PathDivider />
          <EnvironmentSwitcherPagesWrapper />
        </>
      )}
    </motion.div>
  );
}

function ApplicationSwitcherPagesWrapper() {
  const { track } = useAnalytics();
  const { application } = useApplication();
  const { data: applications } = useFetchApplications();

  return (
    <ApplicationSwitcher
      application={application}
      applications={applications ?? []}
      track={track}
    />
  );
}

function EnvironmentSwitcherPagesWrapper() {
  const { track } = useAnalytics();
  const { showModal: showPaymentModal } = usePaymentRequired();
  const { application, mutate } = useApplication();
  const { instance: currentInstance } = useInstance();

  if (!currentInstance?.environment_type) {
    return null;
  }

  return (
    <EnvironmentSwitcher
      track={track}
      invalidateApplication={mutate}
      showPaymentModal={showPaymentModal}
      application={application}
      currentInstance={currentInstance}
    />
  );
}

function PrimaryNavigationPagesWrapper() {
  const { data: settings } = useUserSettings();
  const signUpMode = settings?.sign_up.mode || 'public';

  const { staffModeEnabled: isCommerceEnabled } = useStaffMode({
    feature: 'commerce',
  });
  const { staffModeEnabled: isEventLogsEnabled } = useStaffMode({
    feature: 'eventlogs',
  });

  return (
    <PrimaryNavigation
      signUpMode={signUpMode}
      isCommerceEnabled={isCommerceEnabled}
      isEventLogsEnabled={isEventLogsEnabled}
    />
  );
}

// If you update the logic in this wrapper, remember to update
// the one in the app router as well
const BannerManagerWrapper = () => {
  const { pathname } = useLocation();
  const { state } = useCheckout();
  const isCheckoutVisible = state.isCheckoutVisible;
  const { data: currentSubscription } = useCurrentSubscription();
  const { data: instance } = useInstance();
  const { data: application } = useApplication();
  const { isInstanceHome } = useLocation();

  const hardLimit = currentSubscription?.usage?.mau?.hard_limit ?? -1;
  const totalUnits = currentSubscription?.usage?.mau?.total_units ?? 0;

  if (
    !(application && instance) ||
    isCheckoutVisible ||
    pathname.startsWith('/vercel')
  ) {
    return null;
  }

  const unsupportedFeatures =
    currentSubscription.plan.name === 'Enterprise'
      ? []
      : currentSubscription.unsupported_features;

  return (
    <BannerManager
      showAppInGracePeriodBanner={currentSubscription?.in_grace_period}
      showExceededMAUBanner={hardLimit === 0 ? false : totalUnits > hardLimit}
      showUpdatePasswordEndpointBanner={
        instance?.patch_me_password_state === 'enabled'
      }
      showInstallSDKBanner={
        !isInstanceHome &&
        instance?.environment_type === 'development' &&
        !instance?.has_users
      }
      unsupportedFeatures={unsupportedFeatures}
      features={instance.features}
    />
  );
};
